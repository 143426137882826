h1,
h2,
h3,
h4,
h5,
h6,
p {
  letter-spacing: 0.5px;
}
body {
  margin: 0;
  font-family:
    -apple-system, 'Open Sans', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: 'grayscale';
}

::selection {
  background: #5064b1;
  color: white;
}

code {
  font-family:
    'source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New', 'monospace';
}

p {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  /* line-height: 1.375rem; */
}

a {
  text-decoration: 'none';
}

img {
  max-width: '100%';
  height: 'auto';
}

ol,
ul {
  padding-left: '2rem';
}

td {
  border: '1px solid #fff';
  padding: '1px 4px';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes fadeInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.pin-input::-webkit-inner-spin-button,
.pin-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pin-input {
  -moz-appearance: textfield;
  appearance: textfield;
}

.fade-in-from-top > * {
  opacity: 0;
  animation: fadeInFromTop 0.5s ease-in-out forwards;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-10px);
  }
  20% {
    transform: translateX(10px);
  }
  30% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(10px);
  }
  70% {
    transform: translateX(-10px);
  }
  80% {
    transform: translateX(10px);
  }
  90% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

#header.expand::after {
  animation: expand-border 0.5s forwards ease-out;
}

#header.shrink::after {
  animation: collapse-border 0.5s forwards ease-out;
}

@keyframes expand-border {
  0% {
    width: 0;
    left: 50%;
  }
  100% {
    width: 90%;
    left: 5%;
  }
}

@keyframes collapse-border {
  0% {
    width: 90%;
    left: 5%;
  }
  100% {
    width: 0;
    left: 50%;
  }
}

/* Conversation box styles */
.header-title {
  margin: 0px;
  font-size: 20px;
}

/* AI - Teacher chat input */
.grow-wrap {
  display: grid;
  width: 100%;
  max-height: 120px;
  overflow: hidden;
  position: relative;
}
.grow-wrap::after {
  content: attr(data-replicated-value) ' ';
  white-space: pre-wrap;
  visibility: hidden;
}
.grow-wrap > textarea {
  resize: none;
  max-height: 120px;
  overflow: auto;
}
.grow-wrap > textarea,
.grow-wrap::after {
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  transition: border-bottom 0.2s ease-in-out;
  font: inherit;
  line-height: 1.375rem;
  grid-area: 1 / 1 / 2 / 2;
}

.grow-wrap > textarea:focus {
  outline: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.8);
  transition: border-bottom 0.2s ease-in-out;
}

/* Markdown styles */
.response-markdown {
  width: fill-available;
}

.katex {
  white-space: normal !important;
}

.response-markdown h1,
h2,
h3,
h4,
h5,
h6 {
  font: revert;
  font-weight: 700;
}

.response-markdown p,
h3 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.response-markdown > ul,
ol {
  list-style: auto;
  padding-left: 30px;
}

.response-markdown > :only-child {
  margin-top: 0;
  margin-bottom: 0;
}

@keyframes loader-wave {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-10px);
  }
}
